import { ENV } from '../constants';
import { Blockchain } from 'types/enums';
import useNotification from '@hooks/useNotification';
import { useTranslation } from 'react-i18next';
import { isMobile } from '@helpers/device';

const generateOrderLink = (
  blockchain: Blockchain,
  tokenAddress: string,
  refId: string,
) => {
  const startApp = Object.entries({
    route: [
      'limit-orders',
      blockchain,
      tokenAddress.slice(
        0,
        tokenAddress.includes('::') ? tokenAddress.indexOf('::') : undefined,
      ),
      'trade',
    ].join('=-='),
    ref: refId,
  }).reduce((acc, [key, value]) => {
    return acc ? `${acc}==${key}--${value}` : `${key}--${value}`;
  }, '');

  return `${ENV.BOT_URL}/app?startapp=${startApp}`;
};

export default () => {
  const { t } = useTranslation();
  const notify = useNotification();

  const handleCopy = (referralLink: string) => {
    document.hasFocus() && navigator.clipboard.writeText(referralLink);
    notify(t('alert.copy'), { duration: 5000 });
  };

  return async (
    blockchain: Blockchain,
    tokenAddress: string,
    refId: string,
  ) => {
    const referralLink = generateOrderLink(blockchain, tokenAddress, refId);

    try {
      if (isMobile() && navigator.canShare({ text: referralLink })) {
        const shareData = {
          url: referralLink,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {
          notify('Sharing has been aborted', { type: 'danger' });
        }
      } else {
        handleCopy(referralLink);
      }
    } catch (err) {
      handleCopy(referralLink);
    }
  };
};
