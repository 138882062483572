import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import eyeCrossed from '@assets/icons/eye-crossed.svg';
import eye from '@assets/icons/eye.svg';
import share from '@assets/icons/share.svg';
import shareProfit from '@assets/icons/share-profit.svg';
import chart from '@assets/icons/chart.svg';
import close from '@assets/icons/close.svg';
import story from '@assets/icons/stories-icon.svg';
import { useStores } from '@hooks/useStores';
import useNotification from '@hooks/useNotification';
import { OrdersSummaryResponse } from 'types/order/orders-summary.response';
import IconWithLoading from '@components/common/IconWithLoading';
import ShareProfit from '@components/ShareProfit';
import ShareStory from '@components/ShareStory';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { isAndroid } from '@helpers/device';
import { hasOwnProperty } from '@helpers/object';
import useShareToken from '@hooks/useShareToken';
import { ALL_ORDER_FILTERS } from 'types/order/order.enum';
import { OrderResponse } from 'types/order/order.response';
import cn from 'classnames';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { ShareType } from '../../../types';

interface SummaryModalProps {
  onClose: () => void;
  isHidden?: boolean;
  onCancel?: () => void;
  onHide?: () => void;
  item: OrdersSummaryResponse | null;
  refId?: string;
}

const SummaryModal = ({
                        onClose,
                        onCancel,
                        onHide,
                        isHidden,
                        item,
                        refId = '',
                      }: SummaryModalProps) => {
  const { ordersStore, settingsStore, walletStore, accountStore } = useStores();
  const { currentWallet, network } = accountStore;
  const [w] = useAmplitude();
  const notify = useNotification();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isHiding, setIsHiding] = useState<boolean>(false);
  const [shareLoading, setShareLoading] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const isVisible = !isHidden;
  const [shareStoryItem, setShareStoryItem] = useState<string | null>(null);
  const { t } = useTranslation();
  const handleShareToken = useShareToken();

  const allDisabled = useMemo(() => {
    return isHiding || isCancelling;
  }, [isHiding, isCancelling]);

  const handleClose = () => {
    setShareStoryItem(null);
    setShowModal(false);
    onClose();
  };

  useEffect(() => {
    if (item) {
      setShowModal(true);
    }
  }, [item]);

  const toggleHidden = () => {
    if (item) {
      setIsHiding(true);
      ordersStore
        .hideSummary(item.pairId, isVisible)
        .then((response) => {
          if (response) {
            if (isVisible) {
              ordersStore.loadSummary(true).finally(() => {
                notify(t('order.token-hidden-now'));
                setIsHiding(false);
                handleClose();
              });
            } else {
              if (onHide) {
                onHide();
              } else {
                notify(t('order.token-visible-now'));
                setIsHiding(false);
                handleClose();
              }
            }
          } else {
            notify(t('common.operation-failed'), { type: 'danger' });
          }
        })
        .catch((e) => {
          setIsHiding(false);
          notify(t('common.operation-failed'), { type: 'danger' });
          console.error(e);
        });
    }
  };

  const cancelOrder = async (order: OrderResponse) => {
    return new Promise((resolve, reject) => {
      ordersStore
        .cancelOrder(order.id)
        .then((response) => {
          if (response) {
            resolve(true);
          } else {
            reject();
          }
        })
        .catch((e) => {
          reject();
        });
    });
  };

  const finishCancel = () => {
    notify(t('order.orders-cancelled'));
    handleClose();
  };

  const handleCancel = () => {
    if (!item) return;

    if (item.openOrders === '0') {
      notify(t('order.no-active-orders'), { type: 'danger' });
      return;
    }

    setIsCancelling(true);

    ordersStore
      .loadOrders(item.pairId, 'ACTIVE', ALL_ORDER_FILTERS)
      .then((response) => {
        if (
          response &&
          hasOwnProperty(response, 'content') &&
          Array.isArray(response.content)
        ) {
          const promises = response.content.map((order: OrderResponse) =>
            cancelOrder(order),
          );
          Promise.all(promises)
            .then(() => {
              if (isVisible) {
                ordersStore.loadSummary(true).finally(() => {
                  finishCancel();
                });
              } else {
                if (onCancel) {
                  onCancel();
                } else {
                  finishCancel();
                }
              }
            })
            .catch(() => {
              notify(t('order.orders-cancel-failed'), { type: 'danger' });
              setIsCancelling(false);
            });
        } else {
          console.error(response);
          notify(t('order.cant-get-orders'), { type: 'danger' });
          setIsCancelling(false);
        }
      })
      .catch((e) => {
        console.error(e);
        notify(t('order.cant-get-orders'), { type: 'danger' });
        setIsCancelling(false);
      });
  };

  const handleReset = () => {
    setIsHiding(false);
    setIsCancelling(false);
  };

  useEffect(() => {
    if (!item) {
      handleReset();
    }
    return handleReset;
  }, [item]);

  const handleShareStory = () => {
    if (!item) return;

    setShareLoading(true);
    walletStore.getShareProfitImage(item.pairId, currentWallet?.id || '', network, ShareType.STORY).then((response) => {
      setShareLoading(false);
      // if (!response.success || !response.data) {
      if (!response.success) {
        notify(t('errors.network-error'), { type: 'danger' });
      } else {
        notify(t('trading.picture-sent-in-bot'));
        // setShareStoryItem(response.data);
      }
    }).catch((e) => {
      setShareLoading(false);
      console.error(e);
      notify(e, { type: 'danger' });
    });
  };

  if (!item) {
    return null;
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        onExited={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{item.tokenName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-5">
          <button
            className="btn btn-semi-10 px-3 d-flex align-items-center justify-content-between wd-100p tx-13 tx-semibold mb-3"
            onClick={() => {
              w(
                handleShareStory,
                AmplitudeEvent.STORY_SHARE_CLICKED,
              )();
            }}
            disabled={shareLoading || allDisabled}
          >
            <span>{t('common.share-story')}</span>
            <img src={story} alt="share" className="wd-25" />
          </button>

          <button
            className="btn btn-semi-10 px-3 d-flex align-items-center justify-content-between wd-100p tx-13 tx-semibold mb-3"
            onClick={() => {
              // setSharePosition({ ...item, asStory: false })}
              setShareLoading(true);
              walletStore.getShareProfitImage(item.pairId, currentWallet?.id || '', network).then((response) => {
                console.log('response', response);
                setShareLoading(false);
                if (!response.success) {
                  notify(t('errors.network-error'), { type: 'danger' });
                } else {
                  notify(t('trading.picture-sent-in-bot'));
                }
              }).catch((e) => {
                setShareLoading(false);
                console.error(e);
                notify(e, { type: 'danger' });
              });
            }}
            disabled={shareLoading || allDisabled}
          >
            <span>{t('common.share-profit')}</span>
            <img src={shareProfit} alt="share-profit" className="wd-25" />
          </button>

          <button
            className="btn btn-semi-10 px-3 d-flex align-items-center justify-content-between wd-100p tx-13 tx-semibold mb-3"
            onClick={() =>
              handleShareToken(item!.blockchain, item!.tokenAddress, refId)
            }
            disabled={allDisabled}
          >
            <span>{t('common.share-token')}</span>
            <img src={share} alt="share" className="wd-25" />
          </button>

          <button
            className={cn([
              'btn btn-semi-10 px-3 d-flex align-items-center justify-content-between wd-100p tx-13 tx-semibold mb-3',
              allDisabled ? 'op-6' : '',
            ])}
            disabled={allDisabled}
            onClick={(e) => {
              if (allDisabled) {
                e.preventDefault();
              }
              const link = settingsStore.getChartLink(
                item.blockchain,
                item.pairAddress,
                ordersStore.profile()?.address,
              );
              window.open(link, '_blank', 'noopener noreferrer');
            }}
          >
            <span>{t('order.my-trades')}</span>
            <img src={chart} alt="analytics" className="wd-25" />
          </button>

          <button
            className="btn btn-semi-10 px-3 d-flex align-items-center justify-content-between wd-100p tx-13 tx-semibold"
            onClick={toggleHidden}
            disabled={allDisabled}
          >
            <span>{isVisible ? t('common.hide') : t('common.show')}</span>
            {!isHiding && (
              <img
                src={isVisible ? eyeCrossed : eye}
                alt="eye"
                className="wd-25"
              />
            )}
            <IconWithLoading isLoading={isHiding} className="tx-24 lh-1" />
          </button>

          <button
            className="btn btn-semi-10 px-3 d-flex align-items-center justify-content-between wd-100p tx-13 tx-sembold mt-3"
            onClick={handleCancel}
            disabled={allDisabled}
          >
            <span>{t('order.cancel-all')}</span>
            {!isCancelling && <img src={close} alt="close" className="wd-25" />}
            <IconWithLoading isLoading={isCancelling} className="tx-24 lh-1" />
          </button>
        </Modal.Body>
      </Modal>
      <ShareStory
        data={shareStoryItem}
        refId={refId}
        onShare={() => setShareStoryItem(null)}
        setLoading={setShareLoading}
      />
    </>
  );
};

export default SummaryModal;
