import React, { useMemo, useState } from 'react';
import AmountPercentInput from '@pages/LimitOrders/components/AmountPercentInput';
import OrderSettingsModal from '@pages/LimitOrders/components/OrderSettingsModal';
import GasPrice from '@components/common/GasPrice';
import { useTranslation } from 'react-i18next';
import { useStores } from '@hooks/useStores';
import { IOrderSettings } from 'types';
import { defaultOrderSettings } from '@stores/settings-store-old';
import { observer } from 'mobx-react-lite';
import { ChainId } from '../../../constants';
import { Blockchain } from 'types/enums';

const SellOrderValue = () => {
  const { t } = useTranslation();
  const { tokenStore, ordersStore, settingsStore, tokenTrade } = useStores();
  const { network } = ordersStore;
  const {
    tokenDetails: token,
    gas,
    tokenBalanceFormatted: balance,
  } = tokenStore;
  const { chain, sellAmount: amount } = tokenTrade;
  const {
    orderSettings,
    amounts: { sell: percents },
  } = settingsStore;
  const [percent, setPercent] = useState<string | number | null>(null);

  const settings = orderSettings ?? defaultOrderSettings(chain as Blockchain);

  const updateSettings = (val: IOrderSettings) => {
    settingsStore.setOrderSettings(val);
  };

  const isEther = useMemo(() => {
    return network === ChainId.ETHER;
  }, [network]);

  return (
    token && (
      <>
        <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
          <div className="tx-17 tx-semibold">{t('common.value')}</div>
          {isEther && <GasPrice gas={gas} className="ms-auto me-2" />}
          <OrderSettingsModal data={settings} updateSettings={updateSettings} />
        </div>
        <div className="card pt-0">
          <AmountPercentInput
            amount={amount}
            amountChange={(v) => tokenTrade.setSellAmount(v || 0)}
            symbol={token.quoteToken.symbol}
            amountPercent={percent}
            amountPercentChange={setPercent}
            targetValue={balance?.balance || 0}
            targetValueUsd={balance?.balanceInUsd || 0}
            withSign={false}
            predefined={percents as number[]}
            label={t('common.balance-shrt')}
            calcFromTotal
            simplePercents
            isPercent={tokenTrade.amountPercentMode}
            switchMode={() => tokenTrade.switchAmountMode()}
          />
        </div>
      </>
    )
  );
};

export default observer(SellOrderValue);
