import React, { useMemo } from 'react';
import NetworkSelection from 'components/NetworkSelection';
import { useTranslation } from 'react-i18next';
import { allowedChains, chainLogo, chainName } from '@helpers/chains';
import { useStores } from '@hooks/useStores';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { toKebabCase } from '@helpers/formatters';
import { ChainId } from '../constants';
import arrow from '@assets/icons/chevron-down-gray.svg';
import Vibrutton from '@components/common/Vibrutton';
import cn from 'classnames';

interface NetworkSelectionPillProps {
  className?: string;
  displayAll?: boolean;
  highlight?: ChainId[];
  selected?: ChainId;
  onClick?: (network: ChainId) => void;
}

const NetworkSelectionPill: React.FC<NetworkSelectionPillProps> = ({
  displayAll = false,
  highlight = [],
  className = '',
  selected,
  onClick,
}) => {
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const { accountStore } = useStores();
  const { network } = accountStore;

  if (displayAll) {
    return (
      <div className="d-flex flex-wrap gap-2">
        {allowedChains.map((chain) => (
          <Vibrutton
            key={chain}
            onClick={() =>
              onClick
                ? onClick(chain)
                : w(() => {
                    accountStore.setNetwork(chain);
                  }, AmplitudeEvent.NETWORK_SELECTED)
            }
            className={cn([
              'btn',
              'btn-link',
              'border-solid',
              'border',
              'border-semi-transparent-10',
              'ps-2',
              'pe-2',
              (selected || network) === chain
                ? 'bg-secondary'
                : 'bg-transparent',
              'ht-40',
              'tx-white',
              'text-decoration-none',
              'rounded-pill',
              'overflow-hidden',
              'cur-pointer',
              'd-flex',
              'align-items-center',
              'justify-content-center',
            ])}
          >
            <img
              src={chainLogo(chain)}
              alt="chain"
              width={24}
              className="me-1"
            />
            <span className="me-1">
              {t(`common.${toKebabCase(chainName(chain))}`, {
                defaultValue: chainName(chain),
              })}
            </span>
            {highlight.includes(chain) && <div className="indicator ms-1" />}
          </Vibrutton>
        ))}
      </div>
    );
  }

  return (
    <div
      className={
        'ps-2 pe-2 bg-semi-transparent-10 ht-40 tx-white text-decoration-none rounded-pill overflow-hidden cur-pointer d-flex align-items-center justify-content-center me-auto ' +
        className
      }
    >
      <NetworkSelection
        network={network}
        onChange={w((chain) => {
          accountStore.setNetwork(chain);
        }, AmplitudeEvent.NETWORK_SELECTED)}
      >
        <div className="d-flex align-items-center tx-14">
          <img
            src={chainLogo(network)}
            alt="chain"
            width={24}
            className={`me-1 ${network === ChainId.ALL ? 'd-none' : ''}`}
          />
          <span className="me-1">
            {t(`common.${toKebabCase(chainName(network))}`, {
              defaultValue: chainName(network),
            })}
          </span>
          <img src={arrow} alt="Choose network" width={20} />
        </div>
      </NetworkSelection>
    </div>
  );
};

export default NetworkSelectionPill;
