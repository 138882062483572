import React, { useEffect, useMemo } from 'react';
import { ENV } from '../../constants';
import { useTranslation } from 'react-i18next';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import axios from 'axios';
import useResponseHandler from '@hooks/useResponseHandler';

async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
}

interface ShareStoryProps {
  data: string | null;
  refId: string;
  onShare: () => void;
  setLoading: (state: boolean) => void;
}

const ShareStory = ({ data, refId, onShare, setLoading }: ShareStoryProps) => {
  const {
    t,
  } = useTranslation();
  const WebApp = useWebApp();
  const handleResponse = useResponseHandler();

  const referralLink = useMemo(() => {
    return `${ENV.BOT_URL}?start=${refId}`;
  }, [refId]);

  const handleShareStory = async () => {
    if (!data) return;
    setLoading(true);
    dataUrlToFile(`data:image/png;base64,${data}`, 'share-story-profit.png').then(async (file) => {
      try {
        const fd = new FormData();
        fd.append('file', file);

        const { data } = await axios.post<File, Record<string, string>>(
          'https://file.io',
          fd,
        );
        WebApp.shareToStory(data['link'], {
          widget_link: { url: referralLink, name: 'Alpha DEX' },
          text: `${t('common.share-story-text')} Alpha DEX. ${referralLink}`,
        });
      } catch (e) {
        handleResponse(e as string);
      } finally {
        onShare();
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        handleShareStory();
      }, 100);
    }
  }, [data]);

  return <></>;
};

export default ShareStory;
