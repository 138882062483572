import React, { useEffect } from 'react';
import regi from '@assets/images/regi.png';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import Preloader from '@components/common/Preloader';
import Onboarding from '@pages/Onboarding';
import { useTranslation } from 'react-i18next';
import TechJobs from '@components/TechJobs';
import loading from '@assets/lottie/preloader-white.json';
import Lottie from 'lottie-react';

const Welcome = observer(() => {
  const { authStore, accountStore } = useStores();
  const { isLogged, isActivated, isServiceMode } = authStore;
  const { checkDone, wallets, redirectTo } = accountStore;
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (isServiceMode) return;

    if (!checkDone) {
      return;
    }

    if (wallets.length) {
      navigate(redirectTo ?? PageRoutes.WALLET, { replace: true });
    }
  }, [wallets, checkDone]);

  if (isServiceMode) return <TechJobs />;

  if (!isLogged && isActivated === false) {
    // if (!(!isLogged && isActivated === false)) {
    return <Onboarding />;
  } else if (!checkDone || isActivated === undefined) {
    return (
      <div className="full-page d-flex align-content-center justify-content-center py-5 px-3">
        <Lottie animationData={loading} loop={true} />
      </div>
    );
  } else {
    return (
      <div className="tx-center pb-5">
        <div>
          <img src={regi} alt="Registration" className="wd-100p px-0" />
        </div>
        <h2 className="tx-28">
          {t('welcome.first-web3-wallet-p1')}
          <br />
          {t('welcome.first-web3-wallet-p2')}
        </h2>
        <p className="tx-16 my-3 tx-muted">{t('welcome.alpha-wallet')}</p>

        {(checkDone === 404 || checkDone === 403) && (
          <div>
            <button
              className="btn btn-primary-10 wd-100p"
              onClick={() => navigate(PageRoutes.CREATE_WALLET)}
            >
              {t('wallet.create-wallet')}
            </button>

            {/*<button*/}
            {/*  className="btn btn-semi-10 wd-100p mt-3"*/}
            {/*  onClick={() => navigate(PageRoutes.IMPORT_PIN)}*/}
            {/*>*/}
            {/*  {t('wallet.import-wallet')}*/}
            {/*</button>*/}
          </div>
        )}
      </div>
    );
  }
});

export default Welcome;
