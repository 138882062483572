import {
  action,
  makeAutoObservable,
  observable,
  computed,
  reaction,
} from 'mobx';
import { AuthStore } from './auth-store';
import { AccountStore } from './account-store';
import { ChainId } from '../constants';
import {
  dexScreenerLink,
  dexToolsLink,
  geckoTerminalLink,
} from '@helpers/chains';
import { IOrderSettings, UserSettings } from 'types';
import { hasOwnProperty } from '@helpers/object';
import BigNumber from 'bignumber.js';
import { Blockchain } from 'types/enums';
import api from '@helpers/api';
import {
  defaultSettings,
  getDefaultTradeSettings,
} from '../constants/default-settings';

export const defaultOrderSettings = (
  chain: Blockchain = Blockchain.ETHEREUM,
): IOrderSettings => getDefaultTradeSettings(chain)!.orderSettings;

export type ChartService = 'dexscreener' | 'dextools';

interface AvatarOptions {
  [key: string]: string;
}

export class SettingsStore {
  private _authStore: AuthStore;
  private _accountStore: AccountStore;

  @observable
  private _blockchain: Blockchain;

  @observable
  private _settings: UserSettings;

  @observable
  private _initialized = false;

  @observable
  private _isSaving = false;

  @observable
  private _triggerUpdate = 0;

  @observable
  private _tradeScroll: { [key: string]: number } = {};

  constructor(authStore: AuthStore, accountStore: AccountStore) {
    this._authStore = authStore;
    this._accountStore = accountStore;
    makeAutoObservable(this);

    this.setSettings(defaultSettings);

    reaction(
      () => authStore.isLogged,
      (isLogged) => {
        this.setBlockchain(this._accountStore.network as unknown as Blockchain);
        if (isLogged) {
          this.restoreSettings();
        }
      },
      { fireImmediately: true },
    );

    reaction(
      () => accountStore.network,
      () => {
        this.resetBlockchain();
        this.resetPersistentScroll();
      },
    );

    reaction(
      () => accountStore.currentWallet,
      () => this.resetPersistentScroll(),
    );
  }

  @computed
  get blockchain() {
    return this._blockchain;
  }

  @computed
  get settings() {
    return this._settings;
  }

  @computed
  get tradeSettings() {
    return (
      this.settings.tradeSettings.find(
        ({ blockchain }: { blockchain: Blockchain }) =>
          blockchain === this.blockchain,
      ) ?? getDefaultTradeSettings(this.blockchain)
    );
  }

  @computed
  get initialized() {
    return this._initialized;
  }

  @computed
  get triggerUpdate() {
    return this._triggerUpdate;
  }

  @computed
  get notifyEnabled() {
    return this.settings.notifyEnabled;
  }

  @computed
  get amounts() {
    return this.tradeSettings.amounts;
  }

  @computed
  get percents() {
    return this.tradeSettings.percents.map((p) => new BigNumber(p).toNumber());
  }

  @computed
  get triggers() {
    return this.tradeSettings.triggers.map((t) => new BigNumber(t).toNumber());
  }

  @computed
  get chartService() {
    return this.tradeSettings.chartService;
  }

  @computed
  get analyticsBot() {
    return this.tradeSettings.analyticsBot;
  }

  @computed
  get orderSettings() {
    return Object.keys(this.tradeSettings.orderSettings).length
      ? this.tradeSettings.orderSettings
      : null;
  }

  @computed
  get stopLossSettings() {
    return Object.keys(this.tradeSettings.stopLossSettings).length
      ? this.tradeSettings.stopLossSettings
      : null;
  }

  @computed
  get takeProfitSettings() {
    return Object.keys(this.tradeSettings.takeProfitSettings).length
      ? this.tradeSettings.takeProfitSettings
      : null;
  }

  @computed
  get isSaving() {
    return this._isSaving;
  }

  @computed
  get researchApproved() {
    return this.settings.researchApproved;
  }

  @action.bound
  setBlockchain(blockchain: Blockchain) {
    this._blockchain = blockchain;
  }

  @action.bound
  setSettings(settings: typeof this._settings) {
    this._settings = settings;
  }

  @action.bound
  setTradeSettings(s: Record<string, any>) {
    const tradeSettingsMap = Object.fromEntries(
      this.settings.tradeSettings.map((v: any) => [v.blockchain, v]),
    );
    tradeSettingsMap[this.blockchain] = { ...this.tradeSettings, ...s };
    this._settings.tradeSettings = Object.values(tradeSettingsMap);

    return this.settings;
  }

  @action.bound
  getChartLink(chain: ChainId | string, pairAddress: string, wallet?: string) {
    const link = {
      dextools: dexToolsLink,
      dexscreener: dexScreenerLink,
      geckoterminal: geckoTerminalLink,
    };

    return link[this.tradeSettings.chartService as keyof typeof link](
      chain,
      pairAddress,
    );
  }

  @action.bound
  setChartService(value: 'dexscreener' | 'dextools' | 'geckoterminal') {
    this.setTradeSettings({ chartService: value });
  }

  @action.bound
  setAnalyticsBot(value: string = 'ttfbotbot') {
    this.setTradeSettings({ analyticsBot: value });
  }

  @action.bound
  setOrderCustomization(value: IOrderSettings) {
    this.setTradeSettings(value);
  }

  @action.bound
  setOrderSettings(value: IOrderSettings) {
    this.setTradeSettings({ orderSettings: value });
    this.saveSettings();
  }

  @action.bound
  setStopLossSettings(value: IOrderSettings) {
    this.setTradeSettings({ stopLossSettings: value });
    this.saveSettings();
  }

  @action.bound
  setTakeProfitSettings(value: IOrderSettings) {
    this.setTradeSettings({ takeProfitSettings: value });
    this.saveSettings();
  }

  @computed
  avatarOption(wallet: string) {
    return hasOwnProperty(this.settings?.avatarOptions ?? {}, wallet)
      ? this.settings.avatarOptions[wallet]
      : '';
  }

  @action.bound
  restoreAvatarOptions(options: AvatarOptions) {
    this.settings.avatarOptions = options;
    this.saveSettings();
  }

  @action.bound
  setAvatarOption(wallet: string, value: string) {
    this.settings.avatarOptions[wallet] = value;
    this.handleUpdate();
  }

  @action.bound
  handleUpdate() {
    this._triggerUpdate++;
    this._isSaving = true;
  }

  @action.bound
  setNotifyEnabled(value: boolean) {
    this._settings.notifyEnabled = value;
    this.handleUpdate();
  }

  @action.bound
  setResearchApproved(value: boolean) {
    this._settings.researchApproved = value;
    this.handleUpdate();
  }

  @action
  setOrderValues(value: Record<'buy' | 'sell', string[]>) {
    this.setTradeSettings({ amounts: value });
  }

  @action.bound
  setPercents(value: string[]) {
    this.setTradeSettings({ percents: value });
  }

  @action.bound
  setTriggers(value: string[]) {
    this.setTradeSettings({ triggers: value });
  }

  @action.bound
  setSaved() {
    this._isSaving = false;
  }

  @action.bound
  persistentScroll(key: string) {
    return this._tradeScroll[key] || 0;
  }

  @action.bound
  setPersistentScroll(key: string, value: number) {
    this._tradeScroll[key] = value;
  }

  @action.bound
  resetPersistentScroll() {
    this._tradeScroll = {};
  }

  @action.bound
  resetBlockchain() {
    this._blockchain = this._accountStore.network as unknown as Blockchain;
  }

  @action.bound
  async restoreSettings() {
    this._initialized = false;

    const res = await api<any>({
      method: 'get',
      path: '/user/settings',
    });
    this.setSettings(res);

    this._initialized = true;
  }

  @action.bound
  async saveSettings() {
    this._isSaving = true;

    try {
      await api({
        method: 'post',
        path: '/user/settings',
        data: {
          settings: this.settings,
        },
      });

      this.setSaved();
      return true;
    } catch (e) {
      console.error(e);
      this.setSaved();
      return false;
    }
  }
}
