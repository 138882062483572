import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import isEqual from 'lodash.isequal';
import { observer, useLocalObservable } from 'mobx-react-lite';
import clone from '@helpers/clone';
import { runInAction } from 'mobx';

type Option = {
  value: string | null;
  label: string;
  input?: boolean;
  setInput?: (v: string) => string;
  prefix?: string; // Only for input
  rx?: RegExp;
};
type Props = {
  options: Option[];
  value?: string | null;
  onChange: (selected: Option) => void;
};

const OptionsSelector = (props: Props) => {
  const { options, value, onChange } = props;

  const state = useLocalObservable(
    (): {
      options: Option[];
      selected?: Option;
      setOptions: (o: Option[]) => void;
      setSelected: (o?: Option) => void;
    } => {
      return {
        options,
        selected: options.find(({ value: v }) => value === v),
        setOptions(o) {
          this.options = o;
        },
        setSelected(o) {
          if (o) this.selected = o;
        },
      };
    },
  );

  useEffect(() => {
    state.setOptions(options);
  }, [options]);

  useEffect(() => {
    state.setSelected(options.find(({ value: v }) => value === v));
  }, [value]);

  const prevSelect = useRef<Option>();

  return (
    <div className={cn(['d-flex', 'flex-wrap', 'gap-2'])}>
      {state.options.map((option, idx) => {
        const { value: val, label, input, prefix = '', setInput } = option;

        const value = val ? `${prefix}${val.replace(prefix, '')}` : null;

        return (
          <div className={cn(['flex-1', 'mn-wd-45p'])} key={`option-#${idx}`}>
            {input ? (
              <input
                className={cn([
                  option.value === state.selected?.value
                    ? 'bd-solid bd-primary bg-teal-1i tx-primary'
                    : 'bd-dashed bd-gray-800 tx-muted bg-black-1',
                  'bd-1',
                  'form-control',
                  'pd-y-12',
                  'mx-ht-45',
                  'tx-15',
                ])}
                placeholder={label}
                value={value ?? ''}
                onChange={(e) => {
                  if (option.value !== prefix || e.target.value.length) {
                    runInAction(() => {
                      const v = (setInput && setInput(e.target.value)) ?? '';
                      option.value = v;
                    });
                  }
                }}
                onFocus={() => {
                  runInAction(() => {
                    prevSelect.current = clone(state.selected ?? {});
                    state.setSelected(option);
                    if (!option.value) option.value = prefix;
                  });
                }}
                onBlur={(e) =>
                  runInAction(() => {
                    if (e.target.value === prefix) option.value = null;
                    if (option.value) {
                      state.setSelected(option);
                    } else {
                      const savedInputOption = state.options.find(
                        ({ label }) => label === prevSelect.current?.label,
                      );
                      if (savedInputOption && prevSelect.current)
                        savedInputOption.value = prevSelect.current.value;
                      state.selected = savedInputOption;
                    }

                    if (state.selected && state.selected.value) {
                      state.selected.value = state.selected.value.replace(
                        prefix,
                        '',
                      );
                      onChange(state.selected);
                    }
                  })
                }
              />
            ) : (
              <div
                className={cn([
                  'mx-ht-45',
                  isEqual(option, state.selected)
                    ? 'btn-primary-10 bd-primary'
                    : 'btn-semi-10 bd-transparent',
                  'bd-solid',
                  'bd-1',
                  'btn',
                  'd-flex',
                  'align-items-center',
                  'justify-content-start',
                  'rounded-8px',
                  'tx-15',
                  'tx-normal',
                  'pd-x-12',
                ])}
                onClick={() => {
                  state.setSelected(option);
                  onChange(state.selected!);
                }}
              >
                {label}
              </div>
            )}
          </div>
        );
      })}
      <div className={cn(['flex-1', !(options.length % 2) && 'd-none'])}></div>
    </div>
  );
};

export default observer(OptionsSelector);
