import { Blockchain } from 'types/enums';
import { ITradeSettings, UserSettings } from 'types';
import { SolPriorityMode } from 'types/user/settings.types';

export const defaultSettings: UserSettings = {
  notifyEnabled: false,
  researchApproved: false,
  avatarOptions: {},
  tradeSettings: [
    {
      blockchain: Blockchain.ETHEREUM,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: {
        slippage: 5,
        autoApproveEnabled: true,
        antiMevEnabled: true,
        extraGasPricePercent: 10,
      },
      stopLossSettings: {
        slippage: 5,
        autoApproveEnabled: true,
        antiMevEnabled: true,
        extraGasPricePercent: 10,
      },
      takeProfitSettings: {
        slippage: 5,
        autoApproveEnabled: true,
        antiMevEnabled: true,
        extraGasPricePercent: 10,
      },
    },
    {
      blockchain: Blockchain.BSC,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: { slippage: 5, autoApproveEnabled: true },
      stopLossSettings: { slippage: 5, autoApproveEnabled: true },
      takeProfitSettings: { slippage: 5, autoApproveEnabled: true },
    },
    {
      blockchain: Blockchain.BASE,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: { slippage: 5, autoApproveEnabled: true },
      stopLossSettings: { slippage: 5, autoApproveEnabled: true },
      takeProfitSettings: { slippage: 5, autoApproveEnabled: true },
    },
    {
      blockchain: Blockchain.SOLANA,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: {
        slippage: 5,
        solPriorityMode: SolPriorityMode.FAST,
        antiMevEnabled: true,
      },
      stopLossSettings: {
        slippage: 5,
        solPriorityMode: SolPriorityMode.FAST,
        antiMevEnabled: false,
      },
      takeProfitSettings: {
        slippage: 5,
        solPriorityMode: SolPriorityMode.FAST,
        antiMevEnabled: false,
      },
    },
    {
      blockchain: Blockchain.SUI,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: { slippage: 5 },
      stopLossSettings: { slippage: 5 },
      takeProfitSettings: { slippage: 5 },
    },
    {
      blockchain: Blockchain.TON,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: { slippage: 5 },
      stopLossSettings: { slippage: 5 },
      takeProfitSettings: { slippage: 5 },
    },
    {
      blockchain: Blockchain.TRON,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: { slippage: 5, autoApproveEnabled: true },
      stopLossSettings: { slippage: 5, autoApproveEnabled: true },
      takeProfitSettings: { slippage: 5, autoApproveEnabled: true },
    },
  ],
  pinCodeEnabled: false,
};

export const getDefaultTradeSettings = (blockchain: Blockchain) =>
  defaultSettings.tradeSettings.find((s) => s.blockchain === blockchain) as unknown as ITradeSettings;
