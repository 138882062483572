import React from 'react';
// @ts-ignore
import onboardingVideo from '@assets/videos/VP9.webm';
import { PageRoutes } from '../../constants';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import Vibrutton from '@components/common/Vibrutton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Onboarding = () => {
  const { t } = useTranslation();
  const [w] = useAmplitude([
    AmplitudeEvent.ONBOARDING_SLIDE_VIEWED,
    { slide: 1 },
  ]);
  const navigate = useNavigate();

  return (
    <div className="full-page bg-dark d-flex flex-column justify-content-center overflow-auto">
      <video src={onboardingVideo} autoPlay muted playsInline className="flex-1" />

      <Vibrutton
        className="btn btn-blue mx-3 mb-3 text-center"
        onClick={w(
          () => navigate(PageRoutes.CREATE_WALLET),
          AmplitudeEvent.CREATE_WALLET_CLICKED,
          { slide: 1 },
        )}
      >
        {t('onboarding.create-wallet')}
      </Vibrutton>
    </div>
  );
};

export default Onboarding;
