import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores } from '@hooks/useStores';
import AutomaticLevelToggle from '@components/AlphaOrder/AutomaticLevelToggle';
import CustomLevel from '@components/AlphaOrder/CustomLevel';
import LevelStep from '@components/AlphaOrder/LevelStep';
import { AlphaOrderModel } from '@pages/LimitOrders/models/AlphaOrderModel';
import { limitSign } from '@helpers/calculations';
import BigNumber from 'bignumber.js';

export enum LevelType {
  UPPER = 'upper',
  LOWER = 'lower',
}

type Props = {
  state: AlphaOrderModel;
  type: LevelType.LOWER | LevelType.UPPER;
};

type CustomLevelProps = [
  string | number | null,
  string | number | null,
  (value: number | string | null, noReaction?: boolean) => void,
  (value: number | string | null, noReaction?: boolean) => void,
];

const buyPercentValues = [-25, -50, -75, -90];
const sellPercentValues = [25, 50, 200, 300];

const LevelGroup = ({ state, type }: Props) => {
  const { t } = useTranslation();
  const { tokenTrade, settingsStore } = useStores();

  const { label, currentValue, errors } = state;
  const { isBuy, emptyInputsLightOn } = tokenTrade;

  const {
    autoLevelError,
    excessLimitError,
    upperThresholdSignError,
    lowerThresholdSignError,
    upperLevelError,
    lowerLevelError,
  } = errors;

  const percentButtons = useMemo(
    () =>
      (isBuy ? buyPercentValues : sellPercentValues).map((v) =>
        new BigNumber(v)
          .abs()
          .times(isBuy ? -1 : 1)
          .toString(),
      ),
    [isBuy],
  );

  const showAutoLevelToggle = useMemo(
    () =>
      (!isBuy && type === LevelType.UPPER) ||
      (isBuy && type === LevelType.LOWER),
    [isBuy],
  );

  const showCustomLevelGroup = useMemo(
    () => !showAutoLevelToggle || (showAutoLevelToggle && !state.isAutoLevel),
    [showAutoLevelToggle, state.isAutoLevel],
  );

  const heading = {
    upper: t('order.alpha.upper-threshold'),
    lower: t('order.alpha.lower-threshold'),
  }[type];

  const anotherLevelLabel = {
    upper: t('order.alpha.lower-threshold'),
    lower: t('order.alpha.upper-threshold'),
  }[type];

  const [level, levelPercents, setLevel, setLevelPercents]: CustomLevelProps = {
    upper: [
      state.upperLevel,
      state.upperLevelPercents,
      (v) => state.setUpperLevel(v),
      (v) => state.setUpperLevelPercents(limitSign(v, isBuy)),
    ] satisfies CustomLevelProps,
    lower: [
      state.lowerLevel,
      state.lowerLevelPercents,
      (v) => state.setLowerLevel(v),
      (v) => state.setLowerLevelPercents(limitSign(v, isBuy)),
    ] satisfies CustomLevelProps,
  }[type];

  const anotherLevel = showAutoLevelToggle
    ? {
        upper: [state.lowerLevel, state.lowerLevelPercents],
        lower: [state.upperLevel, state.upperLevelPercents],
      }[type]
    : [];

  const isInValid = useMemo(() => {
    return [
      !!excessLimitError,
      upperThresholdSignError && type === LevelType.UPPER,
      lowerThresholdSignError && type === LevelType.LOWER,
      upperLevelError && emptyInputsLightOn && type === LevelType.UPPER,
      lowerLevelError && emptyInputsLightOn && type === LevelType.LOWER,
    ].includes(true);
  }, [errors, emptyInputsLightOn]);

  const showError = useMemo(() => {
    const excessError = !!excessLimitError;
    const signError = !!(
      (type === LevelType.LOWER && lowerThresholdSignError) ||
      (type === LevelType.UPPER && upperThresholdSignError)
    );

    return [excessError, signError].includes(true) && showCustomLevelGroup;
  }, [errors]);

  return (
    <>
      <div className="card rounded-4-px gap-12px">
        <div>
          <div className="tx-15 tx-semibold">{heading}</div>
          {showAutoLevelToggle && (
            <AutomaticLevelToggle
              value={state.isAutoLevel}
              onClick={(v) => state.setAutoLevel(v)}
            />
          )}

          {showCustomLevelGroup && (
            <CustomLevel
              {...{
                level,
                currentValue,
                label:
                  anotherLevel[0] && showAutoLevelToggle
                    ? anotherLevelLabel
                    : label,
                anotherLevel,
                levelPercents,
                percentButtons,
                setLevel,
                setLevelPercents,
                isInValid,
              }}
            />
          )}

          {showError && (
            <div className="justify-content-between card bg-semi-danger-10 mt-2 pd-12 py-2">
              {excessLimitError && (
                <span className="tx-13 tx-danger">
                  <li>{t('order.alpha.lower-threshold-exceeds-upper')}</li>
                </span>
              )}
              {((type === LevelType.LOWER && lowerThresholdSignError) ||
                (type === LevelType.UPPER && upperThresholdSignError)) && (
                <span className="tx-13 tx-danger">
                  <li>
                    {t(
                      `order.alpha.${type}-threshold-sign-error-${isBuy ? 'buy' : 'sell'}`,
                    )}
                  </li>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {!showCustomLevelGroup && (
        <div className="card rounded-4-px">
          <div className="tx-13 tx-semibold mg-b-12">
            {t('order.alpha.intermediate-step')}
          </div>
          <LevelStep
            value={state.autoLevelStep}
            onChange={(v) => state.setAutoLevelStep(v)}
            isInValid={emptyInputsLightOn && autoLevelError}
          />
        </div>
      )}
    </>
  );
};

export default observer(LevelGroup);
