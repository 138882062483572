import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import FormattedNumber from '@components/common/FormattedNumber';
import IconWithLoading from '@components/common/IconWithLoading';
import useNotification from '@hooks/useNotification';
import { useStores } from '@hooks/useStores';
import useResponseHandler from '@hooks/useResponseHandler';
import { chainLogo, chainName, walletTypeName } from '@helpers/chains';
import { ReferralProfileResponse } from '../../../types/referral/referral-profile.response';
import { BaseToken, Blockchain, WalletType } from '../../../types/enums';
import UserAvatar from '@components/common/UserAvatar';
import { getEllipsisTxt } from '@helpers/formatters';
import Vibrutton from '@components/common/Vibrutton';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { evmChains } from '../../../constants';

interface ReferralClaimModalProps {
  data?: ReferralProfileResponse;
  unclaimedProfit?: number;
  onClaim: () => void;
}

const _fallback: ReferralProfileResponse = {
  team30dUsdVolume: 0,
  total30dUsdVolume: 0,
  whaleChatInviteLink: null,
  wallets: [
    {
      blockchain: Blockchain.ETHEREUM,
      balance: '0',
      token: BaseToken.ETH,
      refWalletId: '0',
      balanceUsd: '0',
    },
    {
      blockchain: Blockchain.SOLANA,
      balance: '0',
      token: BaseToken.SOL,
      refWalletId: '1',
      balanceUsd: '0',
    },
    {
      blockchain: Blockchain.BSC,
      token: BaseToken.BNB,
      balance: "0.230835677848590307580154080946362719",
      balanceUsd: '139.20055784215668',
      refWalletId: "b76305b1-804e-42ff-88dc-109caedbb976",
    },
    {
      blockchain: Blockchain.BASE,
      balance: '0',
      token: BaseToken.ETH,
      refWalletId: '3',
      balanceUsd: '0',
    },
    {
      blockchain: Blockchain.TRON,
      balance: '100',
      token: BaseToken.TRX,
      refWalletId: '4',
      balanceUsd: '1000',
    },
  ],
  personal30dUsdVolume: 0,
  refCode: '',
  lines: [],
  rank: 1,
  personalAllTimeUsdVolume: 0
};

const ReferralClaimModal = observer((props: ReferralClaimModalProps) => {
  const { onClaim, data, unclaimedProfit } = props;
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const { accountStore } = useStores();
  const { currentWallet, wallets } = accountStore;
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notify = useNotification();
  const handleResponse = useResponseHandler();
  const [network, setNetwork] = useState<Blockchain>();
  const [walletToId, setWalletToId] = useState<string>('');
  const [isWalletSelection, setIsWalletSelection] = useState<boolean>(false);
  // const data = _fallback;

  const selectedWallet = data?.wallets.find(
    ({ blockchain }) => blockchain === network,
  );
  const value = selectedWallet?.balanceUsd;

  const walletTo = useMemo(() => {
    return wallets.find((wallet) => wallet.id === walletToId);
  }, [walletToId, wallets]);

  const refWallet = useMemo(() => {
    return data?.wallets.find(({ blockchain }) => blockchain === network);
  }, [data, network]);

  const isInterType = useMemo(() => {
    if (!network || !refWallet) {
      return false;
    }
    if (evmChains.includes(network) && evmChains.includes(refWallet.blockchain)) {
      return true
    }
    return network === refWallet.blockchain;
  }, [network, refWallet]);

  const isSolana = useMemo(() => {
    return refWallet?.blockchain === Blockchain.SOLANA;
  }, [refWallet]);

  const isTon = useMemo(() => {
    return refWallet?.blockchain === Blockchain.TON;
  }, [refWallet]);

  const isTron = useMemo(() => {
    return refWallet?.blockchain === Blockchain.TRON;
  }, [refWallet]);

  const isSui = useMemo(() => {
    return refWallet?.blockchain === Blockchain.SUI;
  }, [refWallet]);

  const isEvm = useMemo(() => {
    return !isSolana && !isTon && !isTron && !isSui;
  }, [isSolana, isTon, isTron, isSui]);

  const types = useMemo(() => {
    const enabledTypes: WalletType[] = [];
    if (isSolana) {
      enabledTypes.push(WalletType.SOL);
    } else if (isTon) {
      enabledTypes.push(WalletType.TON);
    } else if (isTron) {
      enabledTypes.push(WalletType.TRON);
    } else if (isSui) {
      enabledTypes.push(WalletType.SUI);
    }
    const list = Object.values(WalletType);
    if (enabledTypes.length) {
      return list.filter((value) => enabledTypes.includes(value));
    }
    const disabledTypes = isEvm ? [WalletType.SOL, WalletType.TON, WalletType.TRON, WalletType.SUI] : [];
    if (disabledTypes.length) {
      return list.filter((value) => !disabledTypes.includes(value));
    }
    return list;
  }, [isSolana, isTon, isTron, isSui, isEvm]);

  const filteredWallets = useMemo(() => {
    return wallets.filter((w) => types.includes(w.type));
  }, [types, wallets]);

  const handleClose = () => {
    if (isLoading) return;
    setNetwork(undefined);
    setShow(false);
    setWalletToId('');
    setIsWalletSelection(false);
  };

  const handleContinue = () => {
    if (!network) {
      notify(t('referral.select-the-chain'), { type: 'danger' });
      return;
    }

    if (!value) {
      notify(t('referral.nothing-to-claim'), { type: 'danger' });
      return;
    }

    if (!filteredWallets.length) {
      let walletType = 'EVM';
      if (isSolana) {
        walletType = 'Solana';
      } else if (isTon) {
        walletType = 'TON';
      } else if (isTron) {
        walletType = 'TRON';
      } else if (isSui) {
        walletType = 'SUI';
      }
      notify(
        t('referral.please-create-wallet-before-claiming', { walletType }),
        { type: 'danger' },
      );
      return;
    }

    w(setIsWalletSelection, AmplitudeEvent.CLAIM_NETWORK_SELECTED)(true);
  };

  const handleSubmit = () => {
    if (!walletToId) {
      notify(t('referral.select-the-wallet'), { type: 'danger' });
      return;
    }

    if (!network) {
      notify(t('referral.select-the-chain'), { type: 'danger' });
      return;
    }

    if (!value) {
      notify(t('referral.nothing-to-claim'), { type: 'danger' });
      return;
    }

    setIsLoading(true);

    accountStore
      .claimReferral(selectedWallet!.refWalletId, walletToId)
      .then((response) => {
        setIsLoading(false);
        handleClose();
        if (response?.txHash) {
          notify(
            `${t('referral.claimed')} $${new BigNumber(value).toFixed(2)}`,
            { type: 'success' },
          );
          w(onClaim, AmplitudeEvent.CLAIM_SUCCESS, {
            network,
            amount: unclaimedProfit,
            currency: '$',
          })();
        } else {
          handleResponse(response.response, true);
        }
      })
      .catch((response) => {
        setIsLoading(false);
        handleClose();
        handleResponse(response.response.data);
      });
  };

  useEffect(() => {
    if (isInterType) {
      setWalletToId('');
    }
  }, [isInterType, network]);

  const currentWalletFits = useMemo(() => {
    return (
      currentWallet &&
      ((currentWallet.type === WalletType.SOL &&
        network === Blockchain.SOLANA) ||
        (currentWallet.type !== WalletType.SOL &&
          network !== Blockchain.SOLANA))
    );
  }, [currentWallet, network]);

  useEffect(() => {
    if (isWalletSelection && !walletToId && currentWalletFits) {
      setWalletToId(currentWallet!.id);
    }
  }, [isWalletSelection, currentWalletFits, walletToId, currentWallet]);

  if (!data?.wallets.length) {
    return (
      <button className="btn btn-primary-10 py-2 ms-3" disabled>
        {t('referral.claim')}
      </button>
    );
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>{t('referral.claim')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tx-center tx-28 tx-semibold mt-2">
            {!isWalletSelection
              ? t('referral.specify-the-chain')
              : t('referral.specify-the-wallet')}
          </div>
          <div className="tx-center tx-17 tx-muted mt-2 px-3 mb-4">
            {!isWalletSelection
              ? t('referral.when-withdrawing-funds')
              : t('referral.funds-will-be-credited-to-the-specified-wallet')}
          </div>

          {!isWalletSelection &&
            data.wallets.map(({ balanceUsd, balance, token, blockchain }) => (
              <button
                className={`btn btn-semi-10 ${network === blockchain ? 'bg-semi-primary-10' : ''} d-flex align-items-center px-3 py-3 wd-100p mb-3`}
                onClick={() => setNetwork(blockchain)}
                key={`referral-claim-selection-item-${blockchain}`}
              >
                <div className="wd-25 ht-25 d-flex align-items-center justify-content-start rounded-circle me-3 py-3">
                  <img
                    src={chainLogo(blockchain)}
                    alt={blockchain}
                    width={24}
                  />
                </div>
                <div className="d-flex flex-column align-items-start justify-content-center">
                  <div className="tx-17 tx-semibold">
                    {chainName(blockchain)}
                  </div>
                  <div className="tx-12 tx-left">
                    <FormattedNumber value={balanceUsd} suffix="$" subZeros />
                    <FormattedNumber
                      value={balance}
                      suffix=" ("
                      postfix={` ${token})`}
                      className={`${network === blockchain ? 'tx-primary' : 'tx-muted'}`}
                    />
                  </div>
                </div>
              </button>
            ))}

          {isWalletSelection && (
            <>
              {filteredWallets.map(({ id, name, address, type }) => (
                <Vibrutton
                  className={`btn  ${id === walletTo?.id ? 'btn-primary' : 'btn-semi-10'} d-flex align-items-center px-3 py-3 wd-100p mb-3 tx-17 tx-normal`}
                  onClick={() => setWalletToId(id)}
                  key={`claim-wallet-selection-item-${address}-${id}`}
                >
                  <UserAvatar string={address} />
                  <div className="d-flex flex-column align-items-start justify-content-center ms-2">
                    <div>
                      <span className="me-1">
                        {name || getEllipsisTxt(address, 4, '0x')}
                      </span>
                      <span className="tx-uppercase">
                        ({walletTypeName(type)})
                      </span>
                    </div>
                    {!!name && (
                      <div className="tx-13 tx-muted">
                        {getEllipsisTxt(address, 4, '0x')}
                      </div>
                    )}
                  </div>
                </Vibrutton>
              ))}
            </>
          )}

          {!isWalletSelection && (
            <button
              className={`btn ${!network ? 'btn-dark bg-semi-transparent-25' : 'btn-primary-10'} wd-100p mt-4`}
              onClick={handleContinue}
              disabled={isLoading}
            >
              {t('referral.select-wallet')}
            </button>
          )}

          {isWalletSelection && (
            <button
              className={`btn ${isLoading || !walletTo ? 'btn-dark bg-semi-transparent-25' : 'btn-primary-10'} wd-100p mt-4`}
              onClick={w(handleSubmit, AmplitudeEvent.CLAIM_CLICKED)}
              disabled={isLoading}
            >
              <IconWithLoading isLoading={isLoading} />
              {t('referral.claim')}
            </button>
          )}

          <button
            className="btn btn-transparent wd-100p mt-2 mb-3"
            onClick={() => {
              if (isWalletSelection) {
                setIsWalletSelection(false);
                setWalletToId('');
                return;
              }
              w(handleClose, AmplitudeEvent.CLAIM_CANCELLED)();
            }}
          >
            {!isWalletSelection ? t('common.cancel') : t('common.back')}
          </button>
        </Modal.Body>
      </Modal>

      <button
        className="btn btn-primary-10 py-2 ms-3"
        onClick={w(() => setShow(true), AmplitudeEvent.CLAIM_BUTTON_CLICKED)}
      >
        {t('referral.claim')}
      </button>
    </>
  );
});

export default ReferralClaimModal;
