import React, { useState } from 'react';
import cookies from 'js-cookie';
import { observer } from 'mobx-react-lite';
import phone from '@assets/icons/phone-incoming-teal.svg';
import cross from '@assets/icons/cross-teal.svg';
import { useTranslation } from 'react-i18next';
import usePopup from '@hooks/usePopup';
import { useStores } from '@hooks/useStores';

const cookieName = 'custDevBannerClosed';
const minVolumeUsd = 10000;
const url = 'https://calendly.com/ivanidrisovp/45min';

const CustDevBanner = () => {
  const { t } = useTranslation();
  const { accountStore } = useStores();
  const showPopup = usePopup();

  const { refData } = accountStore;
  const [open, setOpen] = useState<boolean>(!cookies.get(cookieName));

  const isVolumeReached =
    (refData.personalAllTimeUsdVolume as number) >= minVolumeUsd;

  return (
    <>
      {isVolumeReached && open && (
        <div
          className="card bg-semi-teal-10 flex-row align-items-start gap-12px mb-2 cur-pointer"
          onClick={() => {
            showPopup({
              message: (
                <>
                  <div className="p-2 d-flex align-items-center bg-semi-teal-10 rounded">
                    <img src={phone} alt="Phone" width={20} />
                  </div>
                  <span className="mg-t-12 tx-semibold">CustDev</span>
                  <div className="tx-13 tx-muted mt-2 mb-3">
                    {t('banners.cust-dev.invite-to-build-alpha-dex')}
                  </div>
                </>
              ),
              buttons: [
                {
                  text: t('banners.cust-dev.book-a-call'),
                  type: 'info',
                  id: 'book-a-call',
                },
              ],
            }).then((result) => {
              if (result === 'book-a-call') {
                window.open(url, '_blank');
              }
            });
          }}
        >
          <img src={phone} alt="Phone" width={20} />
          <span className="tx-primary tx-13">
            {t('banners.cust-dev.become-alpha-dex-co-builder')}
          </span>
          <img
            onClick={(e) => {
              e.stopPropagation();
              cookies.set(cookieName, 'true', {
                expires: 7,
                path: '/',
              });
              setOpen(false);
            }}
            className="btn p-0"
            src={cross}
            alt="Cross"
            width={20}
          />
        </div>
      )}
    </>
  );
};

export default observer(CustDevBanner);
