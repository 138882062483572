import React, { useEffect, useMemo, useState } from 'react';
import { IOrderSettings } from '../../../types';
import { Modal } from 'react-bootstrap';
import settingsIcon from '@assets/icons/settings-teal.svg';
import usePopup from '@hooks/usePopup';
import useNotification from '@hooks/useNotification';
import clone from '@helpers/clone';
import { useTranslation } from 'react-i18next';
import { defaultOrderSettings } from '@stores/settings-store-old';
import { observer } from 'mobx-react-lite';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import Vibrutton from '@components/common/Vibrutton';
import OrderSettings from '@components/Profile/OrderSettings';
import { useStores } from '@hooks/useStores';
import { Blockchain } from 'types/enums';
import isEqual from 'lodash.isequal';

interface OrderSettingsModalProps {
  data: IOrderSettings;
  updateSettings: (data: IOrderSettings) => void;
}

const OrderSettingsModal = ({
  data,
  updateSettings,
}: OrderSettingsModalProps) => {
  const showPopup = usePopup();
  const notify = useNotification();
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const { tokenTrade } = useStores();

  const { chain: tradeChain } = tokenTrade;
  const [show, setShow] = useState<boolean>(false);

  const [settings, setSettings] = useState<IOrderSettings>(
    clone(data ?? defaultOrderSettings(tradeChain as Blockchain)),
  );

  useEffect(() => {
    setSettings(clone(data ?? defaultOrderSettings(tradeChain as Blockchain)));
  }, [show, data]);

  const orderSettings = useMemo(() => data ?? defaultOrderSettings, [data]);

  const [error, setError] = useState<
    { check: boolean; label: string }[] | null
  >(null);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const handleClose = () => {
    if (!isSaved) {
      data && setSettings((prev) => (isEqual(prev, data) ? clone(data) : prev));
    }
    setIsSaved(false);
    setShow(false);
  };

  const handleSubmit = () => {
    if (error?.some(({ check }) => check)) {
      notify(error.find((c) => c.check)?.label + ' is invalid', {
        type: 'danger',
      });
      return;
    }

    setIsSaved(true);
    setTimeout(() => {
      updateSettings(clone(settings));
      handleClose();
    }, 50);
  };

  const handleReset = () => {
    showPopup({
      title: t('order.reset-settings'),
      message: t('order.confirm-reset'),
      buttons: [
        {
          text: t('common.reset'),
          type: 'danger',
          id: 'reset',
        },
        {
          text: t('common.no'),
          id: 'close',
        },
      ],
    }).then((result) => {
      if (result === 'reset') {
        setSettings(clone(defaultOrderSettings(tradeChain as Blockchain)));
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="d-flex justify-content-between wd-100p">
            <span>{t('order.order-settings')}</span>
            <button
              className="btn btn-transparent tx-primary tx-normal p-0 bd-none"
              onClick={w(
                handleReset,
                AmplitudeEvent.RESET_TO_DEFAULT_BUTTON_CLICKED,
              )}
            >
              {t('common.reset')}
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-between">
          <OrderSettings
            data={settings}
            onChange={setSettings}
            onError={setError}
          />

          <div className="mt-auto mb-0">
            <button
              className="btn btn-primary wd-100p mt-3"
              onClick={w(
                handleSubmit,
                AmplitudeEvent.SAVE_SETTINGS_BUTTON_CLICKED,
                { settings },
              )}
            >
              {t('common.save-settings')}
            </button>

            <button
              className="btn btn-semi-10 wd-100p mt-2"
              onClick={handleClose}
            >
              {t('common.close')}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Vibrutton
        className="btn btn-link tx-primary bg-transparent border-1 d-flex justify-content-center align-items-center cur-pointer tx-13 px-0 py-0 text-decoration-none"
        onClick={w(() => setShow(true), AmplitudeEvent.ORDER_SETTINGS_CLICKED)}
      >
        <img
          src={settingsIcon}
          alt="settings"
          width={16}
          height={16}
          className="me-1"
        />
        {t('trading.slippage')}
        {': '}
        {orderSettings.slippage !== null ? orderSettings.slippage : 5}%
      </Vibrutton>
    </>
  );
};

export default observer(OrderSettingsModal);
