import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@hooks/useStores';
import IconWithLoading from '@components/common/IconWithLoading';
import useNotification from '@hooks/useNotification';
import NumericInput from '@components/common/NumericInput';
import PageLoader from '@components/PageLoader';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import NetworkSelectionPill from '@components/NetworkSelectionPill';
import OptionsSelector from '@components/Profile/OptionsSelector';
import OrderSettings from '@components/Profile/OrderSettings';
import { IOrderSettings } from 'types';
import isEqual from 'lodash.isequal';
import { ChainId } from '../../constants';
import { Blockchain } from 'types/enums';

const Customization = observer(() => {
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const { settingsStore } = useStores();
  const { tradeSettings } = settingsStore;
  const navigate = useNavigate();
  const notify = useNotification();

  const {
    amounts,
    percents,
    triggers,
    chartService,
    analyticsBot,
    blockchain: selectedNetwork,
  } = tradeSettings;

  const { initialized, isSaving } = settingsStore;

  const predefinedBots = useMemo(
    () =>
      selectedNetwork === Blockchain.SUI
        ? ['Suianalyzebot']
        : ['SafeAnalyzerbot', 'ttfbotbot', 'PirbViewBot'],
    [selectedNetwork],
  );

  const [botInput, setBotInput] = useState<string | null>(
    predefinedBots.includes(analyticsBot) ? null : analyticsBot,
  );

  useEffect(() => {
    setBotInput((prev) =>
      predefinedBots.includes(analyticsBot) ? prev : analyticsBot,
    );
  }, [predefinedBots]);

  useEffect(() => {
    settingsStore.restoreSettings();

    return () => settingsStore.resetBlockchain();
  }, []);

  const [orderSettingsError, setOrderSettingsError] = useState<
    {
      check: boolean;
      label: string;
    }[]
  >();

  const cleanString = (input: string) => {
    input = input
      .replace('https://t.me/', '')
      .replaceAll('@', '')
      .replaceAll(' ', '')
      .replace(/[^@a-zA-Z0-9_]/g, '');

    const atIndex = input.indexOf('@');
    if (atIndex > 0) {
      input = input.replace(/@/g, '').replace(/^/, '@');
    }

    return input;
  };

  const botInputHandler = (v: string) => {
    const sanitized = cleanString(v);

    setBotInput(sanitized);

    return sanitized;
  };

  const botOptions = useMemo(() => {
    const defaultBots =
      selectedNetwork === Blockchain.SUI
        ? [{ value: 'Suianalyzebot', label: 'Suianalyzebot' }]
        : [
            { value: 'SafeAnalyzerbot', label: 'SafeAnalyzer' },
            { value: 'ttfbotbot', label: 'TTF Bot' },
            { value: 'PirbViewBot', label: 'PIRBViewBot' },
          ];

    return [
      ...defaultBots,
      {
        value: predefinedBots.includes(analyticsBot) ? botInput : analyticsBot,
        label: 'Add bot',
        input: true,
        setInput: botInputHandler,
        prefix: '@',
        rx: /^@?[a-zA-Z0-9_]*$/,
      },
    ];
  }, [analyticsBot, selectedNetwork]);

  const handleClose = () => {
    navigate(-1);
  };

  const handleSaving = async () => {
    if (orderSettingsError?.length) {
      notify(`${t('common.error')} "${orderSettingsError[0]?.label}"`, {
        type: 'danger',
      });

      return;
    }

    const isSuccess = await settingsStore.saveSettings();

    if (isSuccess) {
      w(
        () => notify(t('profile.settings-saved')),
        AmplitudeEvent.CUSTOMIZATION_SETTINGS_SAVED,
        {
          tradeSettings,
        },
      )();
    } else
      notify(t('common.error'), {
        type: 'danger',
      });
  };

  const handleChange = (
    key: string,
    v: number | string | null,
    index: number,
  ) => {
    const handler = {
      percents: (handler: (prev: string[]) => string[]) =>
        settingsStore.setTradeSettings({
          percents: handler(settingsStore.tradeSettings.percents as string[]),
        }),
      triggers: (handler: (prev: string[]) => string[]) =>
        settingsStore.setTradeSettings({
          triggers: handler(settingsStore.tradeSettings.triggers as string[]),
        }),
    }[key];
    handler &&
      handler((prev: any) => {
        const newValues = [...prev];
        newValues[index] = v === null || v === undefined ? '' : v.toString();
        return newValues;
      });
  };

  const valuesConfig = [
    {
      key: 'percents',
      value: percents,
      storePrefix: 'percent-',
      postfix: '%',
    },
    {
      key: 'triggers',
      value: triggers,
      storePrefix: 'trigger-',
      postfix: '%',
    },
  ];

  if (!initialized) {
    return <PageLoader text={t('strategy.loading-settings')} />;
  }

  return (
    <div className="pb-3" id="amount-settings">
      <div className="tx-28 tx-semibold my-3 d-flex align-items-center">
        <span>{t('profile.customization')}</span>
      </div>
      <NetworkSelectionPill
        displayAll
        selected={selectedNetwork as unknown as ChainId}
        onClick={(n) => settingsStore.setBlockchain(n as unknown as Blockchain)}
      />

      <div className="tx-16 tx-semibold my-3 d-flex align-items-center">
        {t('profile.chart-service')}
      </div>

      <OptionsSelector
        options={[
          { value: 'dexscreener', label: 'DexScreener' },
          { value: 'dextools', label: 'DexTools' },
          { value: 'geckoterminal', label: 'Geckoterminal' },
        ]}
        value={chartService}
        onChange={({ value }) => {
          settingsStore.setTradeSettings({ chartService: value });
        }}
      />

      <div className="tx-16 tx-semibold my-3 d-flex align-items-center">
        {t('profile.analytics-bot')}
      </div>

      <div className="d-flex flex-column">
        <OptionsSelector
          options={botOptions}
          value={botOptions.find(({ value }) => value === analyticsBot)?.value}
          onChange={({ value }) => {
            settingsStore.setTradeSettings({ analyticsBot: value });
          }}
        />
      </div>

      {valuesConfig.map(({ key, value, storePrefix, postfix }) => {
        return (
          <React.Fragment key={key}>
            <div className="tx-16 tx-semibold my-3 d-flex align-items-center">
              {t(`profile.${key}-settings`)}
            </div>
            <div className="card pd-12">
              <div className="d-flex justify-content-between align-items-center wd-100p gap-2 tx-center">
                {value.map((a: string | number | null, index: number) => (
                  <NumericInput
                    className="form-control appearance-none tx-center"
                    key={`${storePrefix}${index}`}
                    value={a as string}
                    disabled={isSaving}
                    onChange={(v) => handleChange(key, v, index)}
                    noDecimal
                    {...{ postfix }}
                  />
                ))}
              </div>
            </div>
          </React.Fragment>
        );
      })}

      <div className="tx-16 tx-semibold my-3 d-flex align-items-center">
        {t('order.order-values')}
      </div>

      <div className="card pd-12 gap-2">
        {Object.keys(amounts)
          .sort()
          .map((k) => {
            const key = k as keyof typeof amounts as 'buy' | 'sell';

            return (
              <div className="d-flex flex-column gap-1" key={key}>
                <div className="tx-13 tx-muted">{t(`common.${key}ing`)}</div>
                <div
                  key={`order-values-group-${key}`}
                  className="d-flex justify-content-between align-items-center wd-100p gap-2 tx-center"
                >
                  {(amounts[key] as string[]).map(
                    (a: string | null, index: number) => (
                      <NumericInput
                        className="form-control appearance-none tx-center"
                        key={`${key}-value-${index}`}
                        value={a}
                        disabled={isSaving}
                        onChange={(v) => {
                          const newValues = [...tradeSettings.amounts[key]];
                          newValues[index] =
                            v === null || v === undefined ? '' : v.toString();
                          settingsStore.setTradeSettings({
                            amounts: {
                              ...tradeSettings.amounts,
                              [key]: newValues,
                            },
                          });
                        }}
                        noDecimal
                        prefix={key === 'buy' ? '$' : undefined}
                        postfix={key === 'sell' ? '%' : undefined}
                      />
                    ),
                  )}
                </div>
              </div>
            );
          })}
      </div>

      <div className="tx-16 tx-semibold my-3 d-flex align-items-center">
        {t('order.order-settings')}
      </div>

      <div className="card pd-12">
        <OrderSettings
          chain={selectedNetwork as unknown as ChainId}
          data={tradeSettings.orderSettings as IOrderSettings}
          onChange={(v) => {
            if (!isEqual(v, tradeSettings.orderSettings))
              settingsStore.setTradeSettings({ orderSettings: v });
          }}
          onError={setOrderSettingsError}
        />
      </div>

      <div className="mt-4">
        <button
          className="btn wd-100p btn-primary-10"
          onClick={handleSaving}
          disabled={isSaving}
        >
          <IconWithLoading isLoading={isSaving} className="me-2" />
          {t('common.save')}
        </button>

        <button
          className="btn btn-transparent tx-white wd-100p mt-2"
          onClick={handleClose}
          disabled={isSaving}
        >
          {t('common.close')}
        </button>
      </div>
    </div>
  );
});

export default Customization;
