import React from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import CopyButton from '@components/common/CopyButton';
import { useNavigate } from 'react-router-dom';
import { HotToken } from '../../../types';
import { ChainId, PageRoutes } from '../../../constants';
import LabelValueCard from '@components/common/LabelValueCard';
import timeAgo, { getDateFnsLocale } from '@helpers/timeAgo';
import TokenLogo from '@components/common/TokenLogo';
import { useTranslation } from 'react-i18next';
import { getEllipsisTxt } from '@helpers/formatters';
import share from '@assets/icons/share.svg';
import useShareToken from '@hooks/useShareToken';
import { Blockchain } from 'types/enums';
import AlphaLink from '@components/common/AlphaLink';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import Badge from '@components/common/Badge';

interface ResearchItemProps {
  data: HotToken & { refId: string | null };
  isGrouped?: boolean;
  isLast?: boolean;
  index?: number;
  noButtons?: boolean;
  chain: ChainId;
  chartLink: string;
  analyticsBot: string;
}

const stocks = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12888_536)">
      <path
        d="M14.1403 10.5933C13.7161 11.5963 13.0528 12.4801 12.2082 13.1675C11.3636 13.8549 10.3634 14.325 9.29522 14.5365C8.227 14.7481 7.12321 14.6948 6.08036 14.3812C5.03751 14.0676 4.08735 13.5034 3.31294 12.7378C2.53854 11.9722 1.96347 11.0285 1.63802 9.98931C1.31257 8.9501 1.24664 7.847 1.446 6.77643C1.64535 5.70586 2.10393 4.70042 2.78163 3.84803C3.45933 2.99563 4.33552 2.32222 5.3336 1.88667M14.1595 5.44878C14.4263 6.09309 14.5901 6.77425 14.6456 7.46723C14.6593 7.63843 14.6662 7.72403 14.6322 7.80114C14.6038 7.86555 14.5475 7.9265 14.4856 7.95996C14.4115 8.00001 14.3188 8.00001 14.1336 8.00001H8.5336C8.34691 8.00001 8.25357 8.00001 8.18227 7.96367C8.11955 7.93172 8.06855 7.88072 8.0366 7.818C8.00026 7.7467 8.00026 7.65336 8.00026 7.46667V1.86667C8.00026 1.68143 8.00026 1.58881 8.04031 1.51466C8.07377 1.45272 8.13472 1.39646 8.19913 1.36807C8.27624 1.33407 8.36184 1.34094 8.53304 1.35466C9.22602 1.41022 9.90718 1.57393 10.5515 1.84081C11.3603 2.17584 12.0953 2.6669 12.7143 3.28596C13.3334 3.90502 13.8244 4.63995 14.1595 5.44878Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12888_536">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ResearchItem = ({
                        data,
                        isGrouped = false,
                        isLast = false,
                        index,
                        noButtons = false,
                        chain,
                        chartLink,
                        analyticsBot,
                      }: ResearchItemProps) => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [w, AmplitudeWrapper] = useAmplitude();
  const handleShareToken = useShareToken();

  const handleTrade = () => {
    navigate(`/limit-orders/${chain}/${data.pairAddress}/trade`, { state: { back: PageRoutes.RESEARCH } });
  };

  return (
    <div className={`${isGrouped ? (!isLast ? 'border-semi-transparent border-bottom pb-3' : '') : 'card'} card-blur overflow-hidden mb-3`}>
      <div className="d-flex flex-row justify-content-between align-items-center wd-100p z-index-10">
        {data.isNew && (
          <Badge
            variant="custom"
            label="New"
            className="pos-absolute z-index-100 bg-teal tx-black tx-10"
            style={{ transform: `translate(-10px, -25px)` }}
          />
        )}
        <TokenLogo
          address={data.token.address}
          name={data.token.symbol}
          size={45}
          chain={chain}
          useName
        />

        <div className="wd-100p ms-2">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div className="mx-wd-120 lh-2 tx-line-clamp-2">
              {index !== undefined && (
                <span className="tx-teal me-1">#{index + 1}</span>
              )}
              {data.token.name}
            </div>

            <FormattedNumber
              value={data.price}
              decimals={4}
              suffix="$"
              subZeros
              className="word-break"
              hideNull
            />
          </div>

          <div className="d-flex justify-content-between align-items-center tx-13">
            <CopyButton
              text={data.token.address}
              className="btn btn-link p-0 tx-13 tx-muted tx-left text-decoration-none"
              iconClass="tx-muted ms-1 me-auto"
            >
              CA: {getEllipsisTxt(data.token.address, 4)}
            </CopyButton>
            <FormattedNumber
              value={data.priceChange24h}
              withSign
              className={data.priceChange24h > 0 ? 'tx-success' : 'tx-danger'}
              postfix="%"
              hideNull
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-3">
        <LabelValueCard
          label={t('common.liquidity')}
          value={data.liquidity}
          unit="$"
        />
        <LabelValueCard
          label={t('common.volume')}
          value={data.volume}
          unit="$"
        />
        <LabelValueCard
          label={t('common.mcap-shrt')}
          value={data.marketCap}
          unit="$"
        />
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-2">
        <LabelValueCard
          label={t('order.age')}
          value={timeAgo(
            new Date(data.createdAt),
            false,
            getDateFnsLocale(language),
          )}
          unit="$"
        />
        <LabelValueCard
          label={t('common.holders')}
          value={data.audit?.holders || t('common.na')}
        />
        <LabelValueCard label={t('common.tax')}>
          {data.audit?.buyTax ? (
            <FormattedNumber
              value={data.audit.buyTax}
              postfix="%"
              className="tx-success"
            />
          ) : (
            <span className="tx-success">{t('common.na')}</span>
          )}
          <span className="tx-muted mx-1">/</span>
          {data.audit?.sellTax ? (
            <FormattedNumber
              value={data.audit.sellTax}
              postfix="%"
              className="tx-danger"
            />
          ) : (
            <span className="tx-danger">{t('common.na')}</span>
          )}
        </LabelValueCard>
      </div>

      {!noButtons && (
        <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-3 z-index-10">
          <AmplitudeWrapper
            className="btn btn-semi-10 bg-transparent border-semi-transparent bd-1 d-flex align-items-center justify-content-center px-2 py-2 flex-grow-1 mn-wd-max-content"
            eventInput={AmplitudeEvent.TOKEN_CHART_TAB_CLICKED}
            eventProperties={{ token: data.pairAddress }}
          >
            <AlphaLink className="d-flex align-items-center" path={chartLink}>
              {stocks}
            </AlphaLink>
          </AmplitudeWrapper>
          {analyticsBot && (
            <AmplitudeWrapper
              className="d-flex align-items-center btn btn-semi-10 ht-32 py-2 wd-100p"
              eventInput={AmplitudeEvent.TOKEN_ANALYTIC_TAB_CLICKED}
              eventProperties={{ token: data.pairAddress }}
            >
              <AlphaLink
                className="mx-auto d-flex align-items-center tx-13 text-decoration-none text-white "
                path={`https://t.me/${analyticsBot}?start=${data.pairAddress}`}
              >
                {t('common.analytic')}
              </AlphaLink>
            </AmplitudeWrapper>
          )}
          <button
            className="d-flex align-items-center justify-content-center btn ht-32 btn-primary-10 tx-teal py-2 tx-13 wd-100p op-8 ht-40"
            onClick={w(handleTrade, AmplitudeEvent.TOKEN_TRADE_TAB_CLICKED, {
              token: data.pairAddress,
            })}
          >
            {t('common.trade')}
          </button>
          {/*<button*/}
          {/*  className="btn btn-semi-10 py-2 tx-13 bg-semi-transparent d-flex align-items-center justify-content-center"*/}
          {/*>*/}
          {/*  <img src={heart} alt="heart" width={16} height={16} className="me-2"/>*/}
          {/*  {formatNumber(999999, undefined, 0)}*/}
          {/*</button>*/}
          <button
            className="btn btn-semi-10 bg-transparent border-semi-transparent bd-1 d-flex align-items-center justify-content-center px-2 py-2 flex-grow-1 mn-wd-max-content"
            onClick={w(
              () =>
                handleShareToken(
                  chain as unknown as Blockchain,
                  data.token.address,
                  data.refId!,
                ),
              AmplitudeEvent.SHARE_BUTTON_CLICKED,
              { token: data.pairAddress },
            )}
          >
            <img src={share} alt="more" width={16} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ResearchItem;
